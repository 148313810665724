import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import './User.css'
import axios from 'axios';
import UserLocalStorage from '../../Utils/UserLocalStorage';

export default function User(){
    const [UserToken] = useState(UserLocalStorage.getToken())

    const [ userName, setUserName ] = useState(null)
    const [ userEmail, setUserEmail ] = useState(null)
    const [ userTel, setUserTel ] = useState(null)
    const [ userDni, setUserdni ] = useState(null)
    const [ userAddress, setUserAddress ] = useState(null)
    const [ userPass, setUserPass ] = useState(null)
    const [ userPass2, setUserPass2 ] = useState(null)
    const [ loading, setLoading ] = useState(false)

    const [ response, setResponse ] = useState(false)
    const [ message, setMessage ] = useState(null)
    const [ errorMessage, setErrorMessage ] = useState(false)
    const [ mailValido, setMailValido ] = useState(true)

    const handlePasswordChange = (e) => {
      const { name, value } = e.target;
      name === 'password1' ? setUserPass(value) : setUserPass2(value);
    };

  function validateObj() {
    
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if(pattern.test(userEmail)){
      setMailValido(true)
      console.log('mail valido')
      if(userPass === userPass2){
        console.log('passwords iguales')
        editaUser()
      }
      else{
        console.log('passwords no coinciden')
      }  
    }else{
      setMailValido(false)
      console.log('mail no valido')
    }

  }

  async function editaUser(){
    setLoading(true)

    const obj = {
      'userToken' : UserToken.replace('"','').replace('"',''),  
      'userName' : userName,
      'userAddress' : userAddress,
      'userEmail' : userEmail,
      'userTel' : userTel,
      'userDni' : userDni,
      'userPass' : userPass
    }

    console.log('enviando > ', obj)

    await axios.put(process.env.REACT_APP_API_URL + `/users/update`, obj)
    .then((response) => {

      let res = response.data;

      console.log(res)
      setResponse(false)
      setResponse(true)
      setErrorMessage(res.ok)
      setMessage(res.message)
      setLoading(false)
    }, (error) => {
      setResponse(false)
      setResponse(true)
      setErrorMessage(false)
      setMessage("¡Ups!, ah ocurrido un error")
      setLoading(false)
      console.log('Error al updatear usuario - ', error)
    });
  }

  async function getUser(token){
    setLoading(true)
    await axios.get(process.env.REACT_APP_API_URL + '/users/getUser/?userToken='+token.replace('"','').replace('"',''))
    .then((response) => {
        let res = response.data.obj

        setUserName(res.nombre)
        setUserAddress(res.direccion)
        setUserdni(res.dni)
        setUserEmail(res.email)
        setUserTel(res.telefono)

        setLoading(false)
    }, (error) => {
        setLoading(false)
        console.log('Error al obtener datos del usuario - ', error)
    });
  }

  useEffect(()=>{
    getUser(UserToken)
    console.log("Obtener usuario: " + UserToken)
  },[]);

return(
    <section className="editUser-page">

<div className='caja-puntos'>
        <h1>
          Perfil de usuario
        </h1>
      </div>

       { response ?
        <div className='box-container message-box'>
          <div className={`card-component ${ !errorMessage ? 'error' : '' }`}>
            <h4 className='message'>{message}</h4>
          </div>
        </div>
        : null
        }

        <div className='box-container'>
        <form className="card-component" autoComplete='off'>
          <fieldset>
            <label htmlFor="">Nombre</label>
            <input type="text" placeholder="Juana Molina" name='name' onChange={(e)=>setUserName(e.target.value)} value={userName}/>
          </fieldset>
          <fieldset>
            <label htmlFor="">Domicilio</label>
            <input type="address" name='address' placeholder="Cale 1234 CABA" onChange={(e)=>setUserAddress(e.target.value)} value={userAddress}/>
          </fieldset>
          <fieldset>
            <label htmlFor="">dni</label>
            <input type="number" disabled="true" placeholder="11111111" maxLength={8} onChange={(e)=>setUserdni(e.target.value)} value={userDni}/>
          </fieldset>
          <fieldset>
            <label htmlFor="">Teléfono</label>
            <input type="number" maxLength={10} onChange={(e)=>setUserTel(e.target.value)} value={userTel}/>
          </fieldset>
          <fieldset>
            <label htmlFor="">email</label>
            <input 
              type="email"
              name="email"
              placeholder="Tu correo@electronico.com" 
              onChange={(e)=> {setUserEmail(e.target.value)}} 
              value={userEmail}

              className={mailValido ? '':'invalidInput'}

            />
            {mailValido ?
              null
             :
              <div className='errorMessage'>Mail no valido</div>
            }
          </fieldset>
          <fieldset>
            <label htmlFor="">Nueva contraseña</label>
            <input type="password" name='password1' placeholder="Nueva contraseña" onChange={handlePasswordChange}/>
            <label>Repetir contraseña</label>
            <input type="password" name='password2' placeholder="Repetir contraseña" onChange={handlePasswordChange}/>
          </fieldset>
        </form>
        <div className="margin-16">
          {
            loading ? 
            <div className='loading-icon'>
              <AiOutlineLoading3Quarters className='Giro'/>
            </div>
            :
            <button className='bg-violeta txt-white' onClick={()=> validateObj()}>Guardar</button>
          }
        </div>

        </div>
    </section>
)
}

