import React, { Suspense, lazy, useState } from 'react'
import { LoginButton, RegistrateButton } from '../Login/Login'
import './Home.css'
import UserLocalStorage from '../../Utils/UserLocalStorage'

const SLIDE_COUNT = 5;
const slides = Array.from(Array(SLIDE_COUNT).keys());
const EmblaCarousel = lazy(() => import ('../CommonComponents/CarouselEmbla'))

export default function Home() {

  const [logueado] = useState(UserLocalStorage.checkUserLocalData())

  return (
    <article className='home-page'>
      <Suspense><EmblaCarousel slides={slides}/></Suspense>
      {!logueado && 
        <div className='botonera margin-16'>
          <LoginButton/>
          <br /><br /> 
          <RegistrateButton/>
        </div>
      }

    </article>
  )
}
