import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from 'axios';
import './Login.css'
import UserLocalStorage from '../../Utils/UserLocalStorage';

function Login(){
  const navigate = useNavigate()
  const [ userLogin, setUserLogin ] = useState('')
  const [ passLogin, setPassLogin ] = useState('')
  const [ loading, setLoading ] = useState(false)

  const [ response, setResponse ] = useState(null)
  const [ message, setMessage ] = useState(null)
  const [ mailValido, setMailValido ] = useState(true)


  function validateObj() {
    
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if(pattern.test(userLogin)){
      setMailValido(true)
      loginUser()
    }else{
      setMailValido(false)
      console.log('mail no valido')
    }

  }


  async function loginUser() {

    setLoading(true)
    const obj = {
      'email':userLogin,
      'pass':passLogin
    }

    console.log('enviando > ', obj)

    await axios.post(process.env.REACT_APP_API_URL + `/users/login`, obj)
    .then((response) => {

      let res = response.data;
      console.log('res',res)


      if(res.status == '601'){
        UserLocalStorage.setToken(res.pasar, res.nombre, Math.round(res.puntos))
        navigate('/home')
        window.location.reload()
      }else{// falta terminar el registro
        setResponse(true)
        setMessage(res.message)
      }


    }, (error) => {
      setLoading(false)
      console.log('no volvió D: > ', error)

    });

    //navigate('/home')
  }

  if(response){
    return(
      <article className="registro-page">
        <img className='logo-login' src={require('../../assets/Images/logoFinal.avif')} alt="" />
  
        <div className='box-container message-box'>
          <div className="card-component">
            <img src={require('../../assets/Images/emailValidation.avif')} alt="" />
            <h4 className='message'>{message}</h4>
          </div>
          <button className='bg-rojo txt-white' onClick={()=>window.location.reload()}>Ingresar</button>
        </div>
      </article>
    )
  }

  return (
    <article className="registro-page">

      <img className='logo-login' src={require('../../assets/Images/logoFinal.avif')} alt="" />

      <div className='box-container'>
        <div className="card-component">
          <fieldset>
            <label>Ya tengo cuenta</label>
            <input 
              type="email"
              name="email"
              autoComplete="off"
              placeholder="Tu correo@electronico.com" 
              onChange={(e)=> {setUserLogin(e.target.value)}} 
              value={userLogin}

              className={mailValido ? '':'invalidInput'}

            />
            {!mailValido && <div className='errorMessage'>Mail no valido</div> }
          </fieldset>
          <fieldset>
            <input type="password" name='password' placeholder="Contraseña" onChange={(e)=> setPassLogin(e.target.value)} value={passLogin}/>
          </fieldset>
        </div>

        <div className='margin-16 link-text text-white forgetPass' onClick={()=> navigate('/forget-pass')}>Olvidé mi Contraseña</div>

        <div className="margin-16">
          {
            loading ? 
            <div className='loading-icon'>
              <AiOutlineLoading3Quarters className='Giro'/>
            </div>
            :
            <button className="bg-rojo txt-white" onClick={()=> validateObj()}>Ingresar</button>
          }
          <div className="width-100 rowFlex	spaceBetween margin-top-16">
            <div className="link-text text-white" onClick={()=>navigate('/home')}>Volver</div>
            <p className='link-text text-white pipeElement'>|</p>
            <div className='link-text text-white' onClick={()=> navigate('/sing-up')}>Registrarse</div>
          </div>
        </div>
      </div>
    </article>
  )
}

  function RegistrateButton(){
    const navigate = useNavigate()

    return(
      <button className="bg-rojo txt-white" onClick={()=> navigate('/sing-up')}>Registrarse</button>
    )
  }

  function LoginButton(){
    const navigate = useNavigate()

    return(
      <button className="bg-violeta txt-white"  onClick={()=> navigate('/login')}>
        Ingresar
      </button>
    )
  }

export default (Login);
export{ RegistrateButton, LoginButton }