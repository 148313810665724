import React, { Suspense } from "react";
import UserLocalStorage from "../../Utils/UserLocalStorage";
import Navbar from "../Navigation/Navbar";

export default class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      logged: UserLocalStorage.loggedIn(),
    };
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight});
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    let clssName = this.state.width>414?(this.state.width>1024?'Desktop':'Tablet'):'Mobile';

    const Children = this.props.children;

    return (
      <main className={clssName}>
        {!this.props.HideTheNavbar && <Navbar pageName={this.props.pageName} logged={true}/>}

        <Suspense fallback={ 'Cargando...' } >
          <Children/>
        </Suspense>
      </main>
    );
  }
}