
import decode from 'jwt-decode';
const TOKEN_NAME = 'fidelizacion_token';

const UserLocalStorage = {

  checkUserLocalData: function() {
    var UserSessionStorage = sessionStorage.getItem(TOKEN_NAME);
    if(!UserSessionStorage){
      //sessionStorage.setItem(TOKEN_NAME, JSON.stringify(objDefault));
      //console.log(':D No hay un token guardado')
      return false
    }else{
      //console.log(':D Ya hay un token guardado')
      return true
    }
  },

  setToken: function(idToken, nombre, puntaje) {
    //let reverse = idToken.split('').reverse().join('');
    //let reverse = idToken.split('');

    // Saves user token to sessionStorage
    sessionStorage.setItem(TOKEN_NAME, JSON.stringify(idToken))
    sessionStorage.setItem('nombre', JSON.stringify(nombre.toString()))
    sessionStorage.setItem('puntaje', JSON.stringify(puntaje))
  },

  setPuntaje: function(puntaje) {
    sessionStorage.setItem('puntaje', JSON.stringify(puntaje))
  },

  getToken: function() {
    let token = sessionStorage.getItem(TOKEN_NAME) ? sessionStorage.getItem(TOKEN_NAME) : ''
    return token
  },

  getNombre: function() {
    let nombreUsuario = sessionStorage.getItem('nombre') ? sessionStorage.getItem('nombre') : ''
    return nombreUsuario
  },

  getPuntaje: function() {
    let puntajeUsuario = sessionStorage.getItem('puntaje') ? sessionStorage.getItem('puntaje') : ''
    return puntajeUsuario
  },

  loggedIn: function() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken() // GEtting token from localstorage
    return !!token && !this.isTokenExpired(token) // handwaiving here

  },

  isTokenExpired: function(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp * 1000 < Date.now() / 1000) { // Checking if token is expired. N
        return true;
      }
      else
        return false;
      }
      catch (err) {
        return false;
      }
  },

  getUserData:function(){
    var aux = sessionStorage.getItem(TOKEN_NAME) ? sessionStorage.getItem(TOKEN_NAME) : ''
    let reverse = aux.split('');

    try {
      const decoded = decode( 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.' + aux + '.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c');

      console.log(decode)

      const userData = {
        token: reverse,
        
        id: decoded.id,
        //email: decoded.email,
        //name: decoded.name,
        //user_dni: decoded.user_dni,
        //user_domi: decoded.user_domi,
        //user_domi_cp: decoded.user_domi_cp,
        //user_domi_dpto: decoded.user_domi_dpto,
        //user_tel: decoded.user_tel,
      }
  
      return decoded;

    } catch(error) {
      console.log('no hay UserData')
      return null;
    }
  },

  logout:function() {
    sessionStorage.removeItem(TOKEN_NAME);
    sessionStorage.removeItem('puntaje');
    sessionStorage.removeItem('nombre');
  },

  reverseToken:function(texto){
    let reverse = texto.split('').reverse().join('');

     //console.log(texto)
     console.log(reverse)
  },

  decodeToken:function(token){
    const decoded = decode('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.' + token + '.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c');

    if(this.isTokenExpired(decoded)){
      return('token expirado')
    }else{
      return decoded.data
    }
  }
}

export default UserLocalStorage;