import React from "react";
import Home from "../Home/Home";
import Layout from "../Layout/Layout";
import SingUp from "../Login/SingUp";
import Login from "../Login/Login";
import Premios from "../Premios/Premios";
import Puntos from "../Puntos/Puntos";
import Scanner from "../Scanner/Scanner";
import ForgetPass from "../Login/ForgetPass";
import Welcome from "../Login/Welcome";
import User from "../User/User";

const RoutesUrls = [

  /* ======== PUBLICAS ======== */

  {
    path: "/",
    exact: true,
    label: 'label',
    main: () => <Layout children={ Home } pageName={'Home'} />
  },

  {
    path: "/Home",
    exact: true,
    label: 'label',
    main: () => <Layout children={ Home } pageName={'Home'} />
  },

  {
    path: "/login",
    exact: true,
    label: 'label',
    main: () => <Layout children={ Login } HideTheNavbar={true} pageName={'Login'} />
  },

  {
    path: "/sing-up",
    exact: true,
    label: 'label',
    main: () => <Layout children={ SingUp } HideTheNavbar={true} pageName={'SingUp'} />
  },

  {
    path: "/forget-pass",
    exact: true,
    label: 'label',
    main: () => <Layout children={ ForgetPass } HideTheNavbar={true} pageName={'ForgetPass'} />
  },

  {
    path: "/forget-pass/:codigo/:emailUsuario",
    exact: true,
    label: 'label',
    main: () => <Layout children={ ForgetPass } HideTheNavbar={true} pageName={'ForgetPass'} />
  },

  {
    path: "/welcome",
    exact: true,
    label: 'label',
    main: () => <Layout children={ Welcome } HideTheNavbar={true} pageName={'welcome'} />
  },

];

const PrivateRoutes = [

  /* ======== PUBLICAS ======== */

  {
    path: "/",
    exact: true,
    label: 'label',
    main: () => <Layout children={ Home } pageName={'Home'} />
  },

  {
    path: "/Home",
    exact: true,
    label: 'label',
    main: () => <Layout children={ Home } pageName={'Home'} />
  },

  {
    path: "/login",
    exact: true,
    label: 'label',
    main: () => <Layout children={ Login } HideTheNavbar={true} pageName={'Login'} />
  },

  {
    path: "/sing-up",
    exact: true,
    label: 'label',
    main: () => <Layout children={ SingUp } HideTheNavbar={true} pageName={'SingUp'} />
  },

  {
    path: "/forget-pass",
    exact: true,
    label: 'label',
    main: () => <Layout children={ ForgetPass } HideTheNavbar={true} pageName={'ForgetPass'} />
  },

  {
    path: "/puntos",
    exact: true,
    label: 'label',
    main: () => <Layout children={ Puntos } pageName={'puntos'} />
  },

  {
    path: "/premios",
    exact: true,
    label: 'label',
    main: () => <Layout children={ Premios } pageName={'Premios'} />
  },

  {
    path: "/qr-scanner",
    exact: true,
    label: 'label',
    main: () => <Layout children={ Scanner } pageName={'Scanner'} />
  },

  {
    path: "/user",
    exact: true,
    label: 'label',
    main: () => <Layout children={ User } pageName={'User'} />
  },
  

];


export default RoutesUrls;
export{PrivateRoutes}

