import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from 'axios';
import './Login.css'

function SingUp(){
  const navigate = useNavigate()

  const [ userName, setUserName ] = useState(null)
  const [ userEmail, setUserEmail ] = useState(null)
  const [ userTel, setUserTel ] = useState(null)
  const [ userDni, setUserdni ] = useState(null)
  const [ userDomi, setUserDomi ] = useState(null)
  const [ userPass, setUserPass ] = useState(null)
  const [ loading, setLoading ] = useState(false)

  const [ response, setResponse ] = useState(null)
  const [ message, setMessage ] = useState(null)
  const [ mailValido, setMailValido ] = useState(true)


  function validateObj() {
    
    var mailValidationRegexp = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    if(mailValidationRegexp.test(userEmail)){
      setMailValido(true)
      console.log('mail valido')
      registraUser()
    }else{
      setMailValido(false)
      console.log('mail no valido')
    }
  }

  async function registraUser(){
    setLoading(true)

    const obj = {
      'action':'registro',
      'userName' : userName,
      'userDomi' : userDomi,
      'userEmail' : userEmail,
      'userTel' : userTel,
      'userDni' : userDni,
      'userPass' : userPass,
    }

    console.log('enviando > ', obj)

    await axios.post(process.env.REACT_APP_API_URL + `/users/registro`, obj)
    .then((response) => {

      let res = response.data;

      setResponse(true)
      setMessage(res[1].message)
    }, (error) => {
      setLoading(false)
      if(error.response.data)
        alert(error.response.data[1].message)
      else 
        alert("Error al iniciar session.")
      //console.log(error);
    });
  }

  return (
    <article className="registro-page">

      <img className='logo-login' src={require('../../assets/Images/logoFinal.avif')} alt="" />

      {
        response ? 
        <div className='box-container message-box'>
          <div className="card-component">
            <img src={require('../../assets/Images/emailValidation.avif')} alt="" />
            <h4 className='message'>{message}</h4>
          </div>
          <button className='bg-rojo txt-white' onClick={()=>navigate('/login')}>Ingresar</button>
        </div>
        :
        <div className='box-container'>
        <form className="card-component" autoComplete='off'>
          <fieldset>
            <label htmlFor="">Nombre</label>
            <input type="text" placeholder="Juana Molina" name='name' onChange={(e)=>setUserName(e.target.value)} value={userName}/>
          </fieldset>
          <fieldset>
            <label htmlFor="">Domicilio</label>
            <input type="address" name='address' placeholder="Cale 1234 CABA" onChange={(e)=>setUserDomi(e.target.value)} value={userDomi}/>
          </fieldset>
          <fieldset>
            <label htmlFor="">dni</label>
            <input type="number" placeholder="..." maxLength={8} onChange={(e)=>setUserdni(e.target.value)} value={userDni}/>
          </fieldset>
          <fieldset>
            <label htmlFor="">Teléfono</label>
            <input type="number" placeholder="..." maxLength={10} onChange={(e)=>setUserTel(e.target.value)} value={userTel}/>
          </fieldset>
          <fieldset>
            <label htmlFor="">email</label>
            <input 
              type="email"
              name="email"
              placeholder="Tu correo@electronico.com" 
              onChange={(e)=> {setUserEmail(e.target.value)}} 
              value={userEmail}

              className={mailValido ? '':'invalidInput'}

            />
            {mailValido ?
              null
             :
              <div className='errorMessage'>Mail no valido</div>
            }
          </fieldset>
          <fieldset>
            <label htmlFor="">Password</label>
            <input type="password" placeholder="8 a 16 Caracteres" onChange={(e)=>setUserPass(e.target.value)} value={userPass}/>
          </fieldset>
        </form>
        <div className="margin-16">
          {
            loading ? 
            <div className='loading-icon'>
              <AiOutlineLoading3Quarters className='Giro'/>
            </div>
            :
            <button className='bg-violeta txt-white' onClick={()=> validateObj()}>Registrarse</button>
          }

          <br />
          
          <div className="width-100 rowFlex	spaceBetween margin-top-8">
            <div className="link-text rowFlex text-white" onClick={()=>navigate('/home')}>Volver</div>
            <p className='link-text text-white pipeElement'>|</p>
            <div className='link-text text-white' onClick={()=>navigate('/login')}>Ya tengo cuenta</div>
          </div>
        </div>

        </div>
      }
    </article>
  )
}

export default (SingUp);