import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Welcome() {
  const navigate = useNavigate()

  return (
    <article className='welcome'>
      <section className="registro-page">
        <img className='logo-login' src={require('../../assets/Images/logoFinal.avif')} alt="" />
  
        <div className='box-container message-box'>
          <div className="card-component">
            <img src={require('../../assets/Images/emailActivation.avif')} alt="" />
            <h4 className='message'>Listo, ya activaste tu cuenta</h4>
          </div>
          <button className='bg-rojo txt-white' onClick={()=> navigate('/login')}>Ingresar</button>
        </div>
      </section>
    </article>
  )
}
