import React, { useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from 'axios';
import './Login.css';

function ForgetPass(){
  const navigate = useNavigate()
  const [ userEmail, setUserEmail ] = useState('')
  let params = useParams()
  const [ password1, setPassword1 ] = useState('')
  const [ password2, setPassword2 ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ response, setResponse ] = useState(null)
  const [ message, setMessage ] = useState(null)

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    name === 'password1' ? setPassword1(value) : setPassword2(value);
  };

  function ChangePassbtn (props) {
    if(props.pass1 !== props.pass2 || (props.pass1 === "" && props.pass2 === ""))
      return (
        <button className='bg-gris txt-white'>Cambiar contraseña</button>
      );
    else
      return (
        <button className='bg-violeta txt-white' onClick={() => recoveryPass()}>Cambiar contraseña</button>
      );  
  };

  async function recoveryPass() {

    setLoading(true)

    if(!params.codigo){
      
      const obj = {
        'emailUsuario':userEmail
      }
  
      await axios.post(process.env.REACT_APP_API_URL + `/users/forgetPass`, obj)
      .then((response) => {
  
        let res = response.data;
        setResponse(true);
        setMessage(res[1].message);
  
      }, (error) => {
        setLoading(false)
        console.log('no volvió D: > ', error)
      });

    }
    else{

      const obj = {
        'emailUsuario':params.emailUsuario,
        'codigo':params.codigo,
        'password':password1
      }
  
      await axios.post(process.env.REACT_APP_API_URL + `/users/changePass`, obj)
      .then((response) => {
  
        let res = response.data;
        setResponse(true);
        setMessage(res[1].message);
        
      }, (error) => {
        setLoading(false)
        console.log('no volvió D: > ', error)
        //console.log(error);
      });

    }
  }

  return (
    <article className="registro-page">

      <img className='logo-login' src={require('../../assets/Images/logoFinal.avif')} alt="" />
{
  response ?
    <div className='box-container message-box'>
      <div className="card-component">
        <img src={require('../../assets/Images/emailValidation.avif')} alt="" />
        <h4 className='message'>{message}</h4>
      </div>
      <button className='bg-rojo txt-white' onClick={()=>navigate('/login')}>Ingresar</button>
    </div>
  :
  params.codigo ?
    <div className='box-container message-box'>
      <div className="card-component">
        <fieldset>
          <label>Nueva contraseña</label>
          <input type="password" name='password1' placeholder="Tu nueva contraseña" onChange={handlePasswordChange} value={password1}/>
          <label>Repetir contraseña</label>
          <input type="password" name='password2' placeholder="Repetir contraseña" onChange={handlePasswordChange} value={password2}/>
          <input type='hidden' name='codigo' value={params.codigo}/>
        </fieldset>
      </div>
      <ChangePassbtn pass1={password1} pass2={password2} />
    </div>
    :
      <div className='box-container'>
        <div className="card-component">
          <fieldset>
            <label>Tu email</label>
            <input type="email" name='email' placeholder="Tu correo@electronico.com" onChange={(e)=> setUserEmail(e.target.value)} value={userEmail}/>
          </fieldset>
        </div>

        
        <div className="margin-16">
          {
            loading ? 
            <AiOutlineLoading3Quarters/>
            :
            <button className="bg-rojo txt-white" onClick={()=> recoveryPass()}>Recuperar contraseña</button>
          }
          <div className="width-100 rowFlex	spaceBetween margin-top-16">
            <div className="link-text rowFlex text-white" onClick={()=>navigate('/login')}>Cancelar</div>
            <div/>
          </div>
        </div>
      </div>
}
    </article>
  )
}

export default (ForgetPass);