import React, { useState } from "react";
import './assets/Styles/Styles.css'
import {BrowserRouter, Routes, Route, Navigate, } from "react-router-dom";
import ViewNotFound404 from "./Components/Routes/ViewNotFound404";
import RoutesUrls, { PrivateRoutes } from "./Components/Routes/Routes";
import UserLocalStorage from "./Utils/UserLocalStorage";

function App() {
  const[userData] = useState(UserLocalStorage.checkUserLocalData())

  console.log(userData)
  
  return (
    <BrowserRouter>
      {userData ? 
        <Routes>
          {PrivateRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              element={<route.main />}
            />
          ))}
          <Route
            path="/*"
            element={<NoMatch/>}
          />

        </Routes>
      :
        <Routes>
          {RoutesUrls.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              element={<route.main />}
            />
          ))}
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      }
    </BrowserRouter>
  );
}

const NoMatch = () => {
  return (
    <ViewNotFound404/>
  );
}

export default (App)