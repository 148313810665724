import React from 'react'


const BoxCanje = (props) => {

  return (
    <article className='premios-page'>
      <img className='PuntosImage' src={require('../../assets/Images/acumulapuntos-canjealosporproductos.avif')} alt="" />

      <div className='caja-puntos'>
        Disponibles <span>{Math.round(props.misPuntos) + ' puntos'}</span>
      </div>
      <ul>
        {
          props.premios.map((premio, p) => 
            <li key={p}>
              <img src={require('../../assets/Images/img-premios/' + premio.url)} alt="" />
              <div>
                <h4>{premio.nombre}</h4>
                <p>
                  {premio.puntos} Puntos
                </p>
              </div>
              <button className='txt-white' onClick={()=> props.onclick(premio)}>Canjear</button>
            </li>
          )
        }
      </ul>
      <div style={{height:'100px'}}></div>
    </article>
  )
}

export default BoxCanje;